<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col md="10">
            <b-input-group>
              <b-input-group-prepend is-text>
                <div
                  v-b-popover.hover.bottom="'Arama kutusu: Firma Adı, Çalışma Sınıfı ve İş alanları içinde arama yapar. Arama yapmak için bir kaç harf giriniz.'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  title="Bilgi"
                  variant="outline-primary"
                >
                  <FeatherIcon icon="HelpCircleIcon" />
                </div>
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                placeholder="Servis Kartı Ara"
              />
              <b-input-group-append>
                <b-button
                  v-if="search !== ''"
                  variant="warning"
                  @click="resetSearch"
                ><FeatherIcon icon="XIcon" /></b-button>
                <b-button
                  variant="primary"
                  :disabled="search === ''"
                  @click="searchData"
                ><FeatherIcon icon="SearchIcon" /></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-button
              to="services/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Oluştur</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(customer)="data">
              <div class="font-weight-bold">
                {{ data.item.customer }}
              </div>
              <div class="text-primary">
                <strong>İlgili Kişi: </strong> {{ data.item.related_person }}
              </div>
              <div
                v-if="data.item.city_out1 && data.item.city_out2"
                class="text-muted"
              >
                {{ data.item.city_out1 }} > {{ data.item.city_out2 }}
              </div>
            </template>
            <template #cell(finished)="data">
              <div class="text-center">
                <b-badge
                  v-if="data.item.finished === '1'"
                  variant="success"
                  pill
                >
                  Tamamlandı
                </b-badge>
                <b-badge
                  v-else
                  variant="warning"
                  pill
                >
                  Devam Ediyor
                </b-badge>
              </div>
            </template>
            <template #cell(syear)="data">
              <div class="text-center">
                {{ data.item.syear }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                :split-to="'/services/view/' + data.item.id"
                split
                block
                size="sm"
                text="Görüntüle"
                variant="outline-primary"
              >
                <b-dropdown-item :to="'/services/edit/' + data.item.id">
                  Güncelle
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Services',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'service_no',
          label: 'NO',
          thStyle: { width: '60px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'customer',
          label: 'FİRMA ADI',
        },
        {
          key: 'work_type',
          label: 'ÇALIŞMA SINIFI',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'job',
          label: 'İŞ',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'finished',
          label: 'DURUM',
          thStyle: { width: '120px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'services.id AS id',
          '0 AS service_no',
          'services.related_person AS related_person',
          'services.finished AS finished',
          'customers.company AS customer',
          'work_types.title AS work_type',
          'jobs.title AS job',
          'cities_out1.title AS city_out1',
          'cities_out2.title AS city_out2',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['services/getServices']
      return data
    },
    dataCount() {
      return this.$store.getters['services/getServicesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'customers.company': this.search,
        'work_types.title': this.search,
        'jobs.title': this.search,
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('services/servicesList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
